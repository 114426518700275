<template>
	<div class="login">
		<div class="logo text-center">
			<a href="https://www.dentrodahistoria.com.br/" target="_blank"
				><img
					width="127"
					:src="require('@/assets/logo-ddh-blue@2x.png')"
					alt="Dentro Educa"
					class
			/></a>
		</div>

		<h3>Área dos pais e familiares</h3>
		<h5>
			Agora precisamos que <b>você se identifique</b> para voltar outras vezes a
			área dos pais e famíliares.
		</h5>

		<form @submit.prevent="doLogin" class="va-row">
			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="text"
						label="Digite seu e-mail"
						id="username"
						name="username"
						maxlength="250"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'usuário'"
						:value="login.username"
						:error="errors.first('username')"
						@input="login.username = $event.target.value"
					/>
				</div>
			</div>

			<div class="flex xs12">
				<div class="input-group">
					<input-normal
						type="password"
						label="Digite sua senha"
						id="password"
						name="password"
						maxlength="50"
						v-validate="'required'"
						:disabled="false"
						:data-vv-as="'senha'"
						:value="login.password"
						:error="errors.first('password')"
						@input="login.password = $event.target.value"
					/>

					<div class="recover-link">
						<router-link :to="{ name: 'recover' }" class="btn btn-micro-edit"
							>Esqueceu a senha?</router-link
						>
					</div>
				</div>

				<div class="error text-center">{{ errors.first("error") }}</div>
			</div>

			<div class="d-flex align--center justify--space-between flex xs12">
				<button class="btn btn-primary" type="submit">Entrar</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	const axios = require("axios");

	export default {
		name: "login-user",

		data() {
			return {
				showSchools: false,
				schoolList: [],

				login: {
					username: null,
					password: null,
				},

				sendActive: true,
			};
		},

		methods: {
			doLogin() {
				const self = this;
				this.errors.clear();

				this.$validator.validateAll().then((result) => {
					if (result) {
						//self.login.username = self.login.username + "@dentrodaescola.com.br"
						self.$store.commit("CLEAR_SESSIONS");

						axios
							.post(process.env.VUE_APP_ROOT_API + "/users/login", self.login)
							.then(function (response) {
								if (response.data.data.user) {
									let parentCredential = {
										token: response.data.data.token,
										user: response.data.data.user,
										main_product: response.data.data.main_product,
										students: response.data.data.students,
										settings: response.data.data.settings,
										selectedStudent: 0,
									};

									self.$store.commit(
										"SAVE_PARENT_CREDENTIALS",
										parentCredential
									);
									self.$router.push({ name: "parents-home" });
								} else {
									let fieldError = {
										field: "error",
										msg: error.response.data.message,
										rule: "error", // optional
									};
									self.$validator.errors.add(fieldError);
								}
							})
							.catch(function (error) {
								// console.log(error)

								let fieldError = {
									field: "error",
									msg: error.response.data.message,
									rule: "error", // optional
								};
								self.$validator.errors.add(fieldError);
							});
					}
				});
			},

			created: function () {
				const self = this;

				// if (this.login.username.length === 11) {

				//let login = this.login.username + "@dentrodahistoria.com.br"

				axios
					.post(process.env.VUE_APP_ROOT_API + "/students/login", self.login)
					.then((res) => {
						self.schoolList = self.createListOfSchools(res.data.data.schools);
						self.login.user_temporary_id = res.data.data.user_temporary_id;
						self.showSchools = true;
					})
					.catch((error) => {
						this.openAddressField = true;
						self.showSchools = false;

						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
				//}
			},

			createListOfSchools(schoolList) {
				let list = [];

				schoolList.forEach((element) => {
					var item = { key: element.id, value: element.name };
					list.push(item);
				});

				return list;
			},

			onChange(event) {
				this.login.school_id = event.target.value;
				this.sendActive = false;
				//this.address = event.target.value
			},

			updateAndVerifyCep({ e, target }) {
				this.login.username = target.value;
				this.created();
			},
		},
	};
</script>

<style lang="scss">
	.select-group {
		width: 100%;
	}
	.select {
		max-width: 100%;
		box-sizing: border-box;
		-ms-touch-action: manipulation;
		-webkit-appearance: none;
		touch-action: manipulation;
		margin-bottom: 0;
		z-index: 1;
		transition: all 150ms ease;
		padding: 4px 4px;
		display: block;
		width: 100%;
		background-color: transparent;
		height: 65px;
		border-radius: 5px;
		border: solid 1px #dfdae4;
		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		padding-left: 20px;
		width: 100%;
	}
	.recover-link {
		position: absolute;
		right: 20px;
		top: 35px;
		width: 150px;

		.btn {
			width: 100%;
		}
	}
	.login {
		font-family: "Nunito";
		width: 35rem;
		text-align: center;

		@include media-breakpoint-down(md) {
			width: 100%;
			padding-right: 2rem;
			padding-left: 2rem;
			.down-container {
				display: none;
			}
		}

		h3 {
			text-align: center;
			font-size: 24px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.17;
			letter-spacing: 1.33px;
			text-align: center;
			color: #68596e;
			font-weight: 800;
			margin-bottom: 10px;
		}

		h5 {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-bottom: 30px;
		}

		.btn-primary {
			width: 100%;
			//font-family: Nunito;
			font-size: 14px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 4;
			letter-spacing: 0.8px;
			text-align: center;
			color: #ffffff;
			border-radius: 40px;
			text-transform: uppercase;
			margin-top: 30px;
		}
		.down-container {
			margin-top: 3.125rem;
		}
	}
</style>
